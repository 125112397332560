.dialog {
    inset: unset !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    border: none !important;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05) !important;
    padding: 40px 50px !important;
    border-radius: 10px !important;
}

.dialog::backdrop {
    background-color: rgba(0, 0, 0, 0.1);
}

.wrapper {
    position: relative;
    width: 460px;
    padding-top: 20px;
}

.closeButton {
    position: absolute;
    width: 30px;
    height: 30px;
    cursor: pointer;
    top: -20px;
    right: -40px;
}

.title {
    width: 100%;
    font-size: 18px;
    color: #ec7765;
    line-height: 22px;
    text-align: center;
}

.text {
    font-size: 14px;
    line-height: 18px;
    color: #666;
    margin-bottom: 40px;
}
