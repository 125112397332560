.container {
    background-color: #fff;
    box-sizing: content-box;
    min-height: 1200px;
    margin: 0;
    position: relative;

    .form {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        padding-bottom: 10px;
        margin-bottom: 22px;
        position: sticky;
        z-index: 102;
        background: white;

        .formItem {
            font-size: 10px;
            color: #666;

            &>div:first-child {
                text-align: left;
                margin-bottom: 4px;
                height: 16px;
            }
        }

        .marginRight {
            margin-right: 10px;
        }

        .marginLeft {
            margin-left: 10px;
        }
    }

    .body {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .vendor:nth-of-type(1) {
            position: sticky;
            left: 0px;
            z-index: 10;
            border-right: 8px solid #01A699;
            width: 310px;

            .headerWrap {
                width: 301px;
                padding-left: 0px;
                padding-right: 0px;
            }
        }

        .vendor {
            display: flex;
            flex-direction: column;
            width: 300px;

            .headerWrap {
                width: 300px;
                padding-left: 10px;
                padding-right: 10px;

                .header {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 0 10px 30px;
                    border-radius: 4px;
                    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
                    position: sticky;
                    top: -200px;
                    background-color: #fff;
    
                    .headerLogo {
                        margin-top: 20px;
                        height: 50px;
                        max-width: 150px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        svg {
                            max-width: 150px;
                            height: 50px;
                            transform: scale(1) !important;
                        }
                        img{max-width: 100% !important;max-height: 50px !important; width: auto !important;}
                    }

                    .platformName {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        font-size: 12px;
                        font-family: "Nunito Sans",sans-serif;
                        color: #666;
                        line-height: 1.5rem;
                        margin-bottom: 20px;
                    }
                }
            }
            
            .productWrap {
                padding-left: 10px;
                padding-right: 10px;
                border-right: 1px solid #D8D8D8;
                background-color: #fff;

                .product {
                    border: 1px solid #E8E8E8;
                    padding: 18px 10px;
                    margin-bottom: 10px;
                    box-shadow: 0px 2px 15px 0px rgba(0,0,0,0.1);
                    background-color: #fff;
                    cursor: pointer;

                    .productImgWrap {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                    }

                    .productTitle {
                        text-transform: uppercase;
                        margin: 12px 0 6px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    
                    .productVendor {
                        margin-bottom: 20px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            .showMoreBtn {
                margin-left: 10px;
                margin-top: 20px;
                margin-bottom: 20px;
                font-size: 12px;
                color: #01A699;
                cursor: pointer;
                display: flex;
                align-items: center;

                svg {
                    width: 18px;
                    height: 18px;
                    fill: #01A699;
                }
            }
        }

        .inputRadio {
            position: absolute;
            border: 1px solid rgb(118, 118, 118);
            width: 13px;
            height: 13px;
            border-radius: 13px;
            z-index: 1;
            pointer-events: none;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .radioChecked {
            background-color: rgb(49, 180, 167);
        }
    }
}

.noData {
    height: 300px;
    font-family: Nunito Sans,sans-serif;
    font-size: 14px;
    color: #999;
    margin-top: 10px;
    text-align: center;
}

.scrollBar {
    position: sticky;
    top: 55px;
    background: #fff;
}

.productListTitle {
    margin-top: 50px;
    border-top: 1px solid #D8D8D8;
    border-right: 1px solid #D8D8D8;
    height: 58px;
    text-align: center;
    font-size: 16px;
    color: #666;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.stickHeaderWrap {
    display: flex;

    .stickHeaderTitle:nth-of-type(1) {
        position: sticky;
        left: 0px;
        z-index: 10;
        width: 310px;
        border-right: 8px solid #01A699;
    }

    .stickHeaderTitle {
        width: 300px;
        border-top: 1px solid #D8D8D8;
        border-right: 1px solid #D8D8D8;
        height: 58px;
        text-align: center;
        font-size: 16px;
        color: #666;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 20px;
        padding-right: 20px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}