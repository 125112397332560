.productPrice {
    display: flex;
    align-items: center;
    height: 16px;
    line-height: 16px;

    span {
        font-family: "Nunito Sans";
        font-size: 11px;
        font-weight: 400;
    }

    .arrowIcon {
        margin: 0px 2px 3px 3px;
        display: inline-block;

        svg {
            width: 11px;
            height: 11px;
            position: relative;
            top: 1px;
        }
    }

    .currency {
        margin-left: 2px;
    }
}
