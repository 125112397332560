@import '~global/styles';

.modalWrapper {
  position: fixed;
  background: $white;
  border-radius: 3px;
  padding: 10px;
  cursor: auto;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
  z-index: 1000;
  width: 436px;
  height: 370px;
  z-index: 500;
  display: flex;
  flex-direction: column;

  .modalHeader {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-bottom: 1px solid $subtle-gray;
    padding-bottom: 10px;
    cursor: move;
    .headerLeading {
      width: 31px;
      height: 31px;
      border-radius: 31px;
      color: #fff;
      background-color: #01a699;
      font-size: 11px;
      line-height: 31px;
      text-align: center;
      margin-right: 6px;
    }

    .headerTitle {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-self: flex-start;
      align-items: flex-start;
      font-size: 14px;
      color: #666666;
      width: 80%;

      .headerTitleInner {
        flex: 1;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .headerDate {
      display: flex;
      align-items: center;
      font-family: 'Nunito Sans';
      font-size: 11px;
      color: #999999;

      svg {
        margin-bottom: 1px;
      }
    }

    .headerSaveBtn {
      margin-left: 10px;
      height: 25px;
    }
  }

  .modalBody {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-bottom: 45px;
    margin: 10px;
    position: relative;
    height: 230px;
    min-height: 230px !important;
    padding-bottom: 0;
    cursor: auto;
    background-color: #fff;
    overflow: hidden;

    .productImage {
      padding-top: 8px;
      padding-left: 12px;
      padding-right: 8px;
      height: 257px;
      width: 131px;
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
        transition: transform 0.1s ease-in-out;
      }

      img {
        object-fit: cover;
        overflow: hidden;
      }

      img,
      > div:first-child {
        width: 107.89px;
        height: 164px;
      }

      .productInfo {
        display: grid;
        background-color: #fff;

        .productTitle {
          width: 107.89px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        > div {
          height: 15px;
        }

        .nameEllipsis {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .actionsContainer {
        display: none;
        position: absolute;
        margin-top: 65px;
        z-index: 100;

        :first-child {
          position: initial;
        }
      }

      &:not(.noHover):hover {
        background: $white;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);

        .actionsContainer {
          display: initial;

          button {
            > div {
              margin-right: 0px;
              position: initial;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: 0px;
            }
          }
        }
      }
    }

    .excludedProduct {
      opacity: 0.4;
      position: relative;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        border-color: transparent;
        border-style: solid;
      }

      &::after {
        border-width: 6px;
        border-right-color: $orange;
        border-top-color: $orange;
        opacity: 0.5;
      }

      img {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
      }
    }
  }

  .modalFooter {
    bottom: 21px;
    width: 100%;
    button {
      margin: 0 auto;

      .plusIcon {
        opacity: 0.3;
        width: 20px;
      }
    }
  }

  .modelFooterExpanded {
    width: 100%;
    padding: 10px;
    background-color: #fff;

    button {
      margin: 0 auto;

      .plusIcon {
        opacity: 0.3;
        width: 20px;
      }
    }
  }
}

.expandIcon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  margin-left: 6px;
}

.bigModal,
.lineBreakCurrencyModal.bigModal {
  width: fit-content;
  width: 870px;
  height: 510px;
  height: fit-content;
  background: $white;
  z-index: 100000;

  .modalBody {
    overflow: scroll;
    width: 830px;
    height: 400px;
    max-height: 400px;
  }
}

.lineBreakCurrencyModal:not(.bigModal):not(.fullScreenModal) {
  height: 390px;

  .modalBody {
    height: 250px;
  }
}

.fullScreenModal,
.lineBreakCurrencyModal.fullScreenModal {
  width: calc(100vw - 80px);
  // height: calc(100vh - 120px);   // 65px 是 Header 的高度, 全屏模态框也要在 Header 下面
  height: 100%;   // 65px 是 Header 的高度, 全屏模态框也要在 Header 下面
  position: fixed;
  // 218px 是外层 div 设置的偏移量, 这里要修正过来
  left: 85px;
  // -180 是外层 div 设置的偏移量, 这里要修正过来
  top: 120px;
  z-index: 100000;
  cursor: default;
  
  .modalHeader {
    cursor: default;
  }

  .bodyWrapper {
    height: calc(100% - 50px);
  }

  .modalBody {
    overflow: scroll;
    width: 100%;
    height: calc(100% - 86px);
  }
}
