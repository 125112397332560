@import '~global/styles';

.product {
  width: 131px;
  height: 257px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0px 10px;
  margin-bottom: 30px;

  &.isExcluded {
    position: relative;
    margin-bottom: 30px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      border-color: transparent;
      border-style: solid;
    }

    &::after {
      border-width: 6px;
      border-right-color: $orange;
      border-top-color: $orange;
      opacity: 0.5;
    }
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    div[class*="legend"] {
      width: 100%;
      height: auto;
      background: transparent;
      position: absolute;
      left: 0;
      right: 0;
      z-index: 1;
      bottom: calc(100% + 8px);

      ul {
        display: flex;
        justify-content: space-around;

        li {
          margin: 0;

          span {
            margin: 0;
          }

          span:last-child {
            display: none;
          }

        }
      }
    }

    div[class*="lengend-next-sibling"] {
      width: 100%;
      height: 8px;
      background: transparent;
      position: absolute;
      left: 0;
      right: 0;
      z-index: 1;
      bottom: 100%;
    }

    .addToLookbook {
      display: none;
      width: 100%;
      position: absolute;
      z-index: 2;
    }

    .description {
      position: relative;
      z-index: 2;
      width: 110px;

      .actions {
        display: none;
      }

      .categoryName {
        padding-top: 0px;
        margin-bottom: -4px;

        span {
          white-space: nowrap;
          overflow: hidden;
          max-width: 110px;
          text-overflow: ellipsis;

          font-size: 12px;
          letter-spacing: -0.09px;
          line-height: 16px;
        }
      }

      .productDetails {
        text-align: left;

        >span {
          font-size: 11px;
          letter-spacing: -0.08px;
          line-height: 13px;
          padding-top: 7px;
        }

        &>div:nth-child(2) {
          padding-top: 2px;
        }

        >* {
          padding-top: 1px;
        }

        &>div:last-child {
          padding-top: 3px;

          &>div {
            height: 16px;
          }
        }

        .price {
          width: 100%;
        }
      }
    }

    .imageWrap {
      height: 163px;
      width: 110px;
      justify-content: center;
      align-items: center;
      display: flex;
      background-color: $white;
      cursor: pointer;
      position: relative;

      >img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
      }
    }

    .bgTransparent {
      background-color: transparent !important;
    }

    .excludedProduct {
      height: 170px;
      width: 110px;
      justify-content: center;
      align-items: center;
      display: flex;
      background-color: $white;
      opacity: 0.4;
      cursor: pointer;
      position: relative;

      img {
        display: none;
        -webkit-filter: grayscale(100%);
        /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
        height: 100%;
      }
    }

    .productImage {
      width: inherit;
    }
  }

  .excluded {
    opacity: 0.4;
  }

  &:not(.noHover):hover {
    z-index: 11;

    .content {
      .description {
        .actions {
          display: flex;

          button {
            >div {
              margin-right: 0px;
              position: initial;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: 0px;
            }
          }
        }
      }

      .addToLookbook {
        display: flex;
      }
    }
  }

  .availability {
    color: $red;
  }

  .noHover {
    pointer-events: none;
  }
}

// Quick View - Product Details Modal
.quickView {
  position: fixed;
  // background: $body-background;
  background: #fff;
  top: 120px;
  left: calc((100vw - 85px - 90%)/2 + 85px);
  width: 90%;
  height: 85%;
  z-index: 100011;
  overflow: scroll;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
}

.productDetailsOverlay {
  position: fixed;
  top: 85px;
  left: 0;
  width: 100%;
  height: calc(100% - 85px);
  z-index: 10;
  background: rgba(255, 255, 255, 0);
}

.saveToText {
  font-size: 12px;
  width: 100%;
  cursor: default;
}

.hoverLookbookItems {
  position: absolute;
  background-color: white;
  color: $green;
  font-size: 12px;
  font-weight: 400;
  border-radius: 4px;
  z-index: 1;

  ul {
    list-style: none;

    li {
      border-bottom: 1px solid #eeeeee;
      padding: 5px 10px;
      cursor: pointer;

      div {
        color: #000;
        cursor: default;
        font-weight: 600;
      }

      &:last-child {
        border-bottom: transparent;
        display: flex;
      }

      svg {
        fill: $green;
        width: 18px;
        height: 18px;
      }

      .createNewText {
        margin: 1px 0 0 5px;
        color: $green;
      }

      &.removeLookbook {
        svg {
          fill: $orange;
        }

        span {
          color: $orange;
        }
      }
    }
  }
}

.createNewText,
.removeLookbook {
  color: $green;
}

